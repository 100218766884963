<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <Icon name="Profile"></Icon>
        ورود توسط ربات
      </h2>
    </template>
    <template #default>
      <p v-if="fail">
        ورود موفق نبود. احتمالا زمان توکن شما منقضی شده لطفا دوباره از ربات لینک دریافت
        کنید
      </p>
      <p v-else>در حال انتقال به پنل</p>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import MnrPassword from "@/components/mnr/MnrPassword.vue";
import Button from "@/components/Button.vue";
import ErrorBox from "@/components/ErrorBox.vue";

export default {
  components: {
    Icon,
    BodySimple,
  },
  data() {
    return {
      firstLoading: true,
      errors: {},
      fail: false,
    };
  },
  mounted() {
    let $this = this;
    $this.$axios
      .post("/api/login-by-token", {
        token: $this.$route.params.loginToken,
      })
      .then(function (response) {
        localStorage.setItem("token", response.data.token);
        $this.$store.commit("userLogged", response.data.user);
        if ($this.$route.query?.goto) {
          $this.$router.push($this.$route.query.goto);
        } else {
          $this.$router.push("/panel");
        }
      })
      .catch(function (error) {
        $this.errors = error.data;
        $this.disabaledForm = false;
        $this.fail = true;
        if ($this.$route.query?.goto) {
          $this.$router.push($this.$route.query.goto);
        } else {
          $this.$router.push("/panel");
        }
      })
      .then(function () {
        $this.firstLoading = false;
      });
  },
  methods: {},
};
</script>
